import { useState, useEffect } from 'react';
import { baseEndpoint } from './API/endpoints';
import { MdHistory } from 'react-icons/md';
import { FaAngleRight, FaAngleLeft, FaBars } from 'react-icons/fa';
import './Leftmenu.css'
import { useNavigate } from 'react-router-dom';
import { TiStarFullOutline } from 'react-icons/ti';
import { SlMenu } from 'react-icons/sl';
import { faL } from '@fortawesome/free-solid-svg-icons';

const Sidebar = ({ leftWidth: initialLeftWidth, onClick, }) => {
  const navigate = useNavigate();
  const [isImageVisible, setIsImageVisible] = useState(false);
  const [activityData, setActivityData] = useState([]); 
  const [loading, setLoading] = useState(true); 
  const [leftWidth, setLeftWidth] = useState(initialLeftWidth);
  const [isOpen, setIsOpen] = useState(initialLeftWidth === '15%');
  const [isMobile, setIsMobile] = useState(false);
  const [ismobileOpen, setIsmobileOpen] = useState(false);

 const toggleSidebar = () => {
  const isMobileOrTablet = window.matchMedia("(max-width: 868px)").matches; // Define breakpoint for mobile/tablet
  let newWidth;

  if (isMobileOrTablet) {
    // For mobile/tablet view
    newWidth = isOpen ? '10%' : '20%';
  } else if(isMobile){  
    // For desktop view
    newWidth = isOpen ? '5%' : '90%';
  }else {
    newWidth = isOpen ? '5%' : '15%';
  }

  setLeftWidth(newWidth);
  setIsOpen(!isOpen);
};

useEffect(() => {
  // Check if the viewport width is less than 760px
  const handleResize = () => {
    setIsMobile(window.innerWidth < 669);
    if (window.innerWidth >= 669) setIsOpen(false); // Close sidebar on desktop view
  };

  window.addEventListener('resize', handleResize);
  handleResize(); // Check on mount

  return () => window.removeEventListener('resize', handleResize);
}, []);


  useEffect(() => {
    if (leftWidth === '15%' || leftWidth === '20%'|| isMobile) {
      setIsImageVisible(true);
    } else {
      setIsImageVisible(false);
    }
  }, [leftWidth,isMobile]);

  const getStartOfWeek = (date) => {
    const resultDate = new Date(date);
    const day = resultDate.getDay();
    const diff = resultDate.getDate() - day; // Adjust when the day is Sunday
    return new Date(resultDate.setDate(diff));
  };
  const getDayDifference = (date1, date2) => {
    const oneDay = 24 * 60 * 60 * 1000; // Hours*Minutes*Seconds*Milliseconds
    return Math.round((date1 - date2) / oneDay);
  };
  const categorizeDate = (activityDate) => {
    const today = new Date();
    const startOfToday = new Date(today.setHours(0, 0, 0, 0));
    const startOfYesterday = new Date(startOfToday);
    startOfYesterday.setDate(startOfToday.getDate() - 1);
    const startOfWeek = getStartOfWeek(startOfToday);
    const startOfLastWeek = new Date(startOfWeek);
    startOfLastWeek.setDate(startOfWeek.getDate() - 7);
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const startOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);

    const dateDifference = getDayDifference(new Date(startOfToday), new Date(activityDate));

    if (dateDifference === 0) return 'Today';
    if (dateDifference === 1) return 'Yesterday';
    if (new Date(activityDate) >= startOfWeek) return 'This Week';
    if (new Date(activityDate) >= startOfLastWeek) return 'Last Week';
    if (new Date(activityDate) >= startOfMonth) return 'This Month';
    if (new Date(activityDate) >= startOfLastMonth) return 'Last Month';
    return 'Older';
  };

  useEffect(() => {
    const fetchActivityData = async () => {
      try {
        const userDetail = JSON.parse(localStorage.getItem('userdetail')) || JSON.parse(localStorage.getItem('userdata'))
        const user_id = userDetail?.id;
        const response = await fetch(`${baseEndpoint}/user_access/users/${user_id}/response`);
        const data = await response.json();
        // console.log('Fetched data:', data);
         // Ensure data is an array
         if (Array.isArray(data)) {
          setActivityData(data);
      } else {
          console.warn('Fetched data is not an array:', data);
          setActivityData([]); // Fallback to empty array
      }
        setLoading(false);
      } catch (error) {
        console.error('Error:', error);
        setActivityData([]);
        setLoading(false);
      }
    };

    // Fetch activity data on component mount
    fetchActivityData();

    // Set up an interval to refresh the activity data every 20 seconds
    const intervalId = setInterval(fetchActivityData, 20000); // 20 seconds

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  const handleItemClick = (item,starred,history) => {
    // Use the title to navigate to a dynamically generated path
    const titleSlug = item.content_type.replace(/\s+/g, '-').toLowerCase(); // Slugify title
    navigate(`/${titleSlug}`, { state: item,starred,ishistory:history }); // Send data to target page
  };



  // const [isMobile, setIsMobile] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const moblietoggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleOutsideClick = (event) => {
    if (event.target.classList.contains('sidebar-overlay')) {
      setIsSidebarOpen(false);
    }
  };

  return (
   

    <>
      {isMobile && (
        <>
          <div className="mobile-menu-icon" onClick={moblietoggleSidebar}>
            
            <SlMenu />
          </div>
          <div
            className={`sidebar-overlay ${isSidebarOpen ? 'active' : ''}`}
            onClick={handleOutsideClick}
          ></div>
        </>
      )}

      <div
        className={`sidebar ${isMobile ? 'mobile-sidebar' : ''} ${isSidebarOpen ? 'open' : ''}`}
        style={{ width: isMobile ? '60%' : leftWidth }}
      >
          <div className="sidebar-header" onClick={()=>{navigate('/');}}>
            <div className="logo-container">
              <img src="/images/Logo.svg" alt="Logo" />
            </div>

            {isImageVisible && <img src="/images/Group 10.svg" alt="Group 10" />}
          </div>
          {isMobile ?(''):(<div 
            onClick={toggleSidebar}
            style={{
              position: 'absolute',
              top: '9%',
              right: '-10px',
              transform: 'translateY(-50%)',
              backgroundColor: '#2563EB',
              color: 'white',
              borderRadius: '50px',
              padding: '4px',
              cursor: 'pointer',
              width:'25px',
              height:'25px',
              display:'flex',
              alignItems:'center',
              fontSize:'22px',
              zIndex: 10,
            }}
          >
            {isOpen ? <FaAngleLeft /> : <FaAngleRight />}
          </div>)}
          

          <div 
            style={{
              height:'91%',
              // overflow: 'scroll',
              transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {leftWidth === '15%'||leftWidth === '20%'|| leftWidth ==='90%'|| isMobile ? (
              <div style={{
                display:'flex',
                flexDirection:'column',
                alignItems:'center',
                opacity: 1,
                transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
                transform: 'translateX(0)',
                width: '100%',
                maxWidth: '300px',  
                // backgroundColor:'green',
                height:'100%',
                // overflowY:'scroll'
              }}> 
                <div  style={{
                  opacity: 1,
                  transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
                  transform: 'translateX(0)',
                  width: '90%',
                  maxWidth: '300px',
                  // backgroundColor:'red',
                  height:'60%',
                  paddingBottom:'20px'
                  // overflowY:'scroll'
                }}>
                  <div style={{
                    padding: '12px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    // fontFamily: 'Plus Jakarta Sans',
                    fontSize: '16px',
                    fontWeight: '500',
                    transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
                    height:'10%',
                  }}>
                    <span className='hestoryHeading'>Recent Activity</span>
                    <MdHistory 
                      size={leftWidth === '15%' ? 20 : 24} 
                      style={{ 
                        transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
                        transform: 'rotate(0deg)',
                        flexShrink: 0,
                        color:'#151D48',
                      }} 
                    />
                  </div>
                  <div className='custom-scrollbar ' style={{ 
                    padding: '12px',
                    opacity: 1,
                    transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
                    overflowY:'scroll',
                    height:'90%',
                    
                  }}>
                    {activityData?.slice().reverse().map((item, index) => (
                      <div 
                        key={index} 
                        onClick={() => handleItemClick(item, 'false',true)} 
                        className='heaster-content_text'
                      >
                        {item.content_type} for {item.file_name?.[0]}
                      </div>
                    ))}
                  </div>
                </div>
                <div  style={{
                  opacity: 1,
                  transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
                  transform: 'translateX(0)',
                  width: '90%',
                  maxWidth: '300px',
                  height:'40%',
                  paddingBottom:'20px',
                  paddingTop:'20px',
                  borderTop:'1px solid #FFFFFF'
                }}>
                  <div style={{
                    padding: '12px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    // fontFamily: 'Plus Jakarta Sans',
                    fontSize: '16px',
                    fontWeight: '500',
                    transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
                    height:'10%',
                  }}>
                    <span className='hestoryHeading'>Saved</span>
                    <TiStarFullOutline
                      size={leftWidth === '15%' ? 20 : 24} 
                      style={{ 
                        transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
                        transform: 'rotate(0deg)',
                        flexShrink: 0,
                        color:'#3E325C',
                      }} 
                    />
                  </div>
                  <div className='custom-scrollbar ' style={{ 
                    padding: '12px',
                    opacity: 1,
                    transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
                    overflowY:'scroll',
                    height:'90%',
                    
                  }}>
                    {activityData?.slice().reverse().map((item, index) => (
                      item.starred && ( // Only render the div if the item is starred
                        <div 
                          key={index} 
                          onClick={() => handleItemClick(item,'true',false)} 
                          className='heaster-content_text'
                        >
                          {item.content_type} for {item.file_name?.[0]}
                        </div>
                      )
                    ))}
                  </div>
                </div>
                
              </div>
              
            ) : (
              
              <div 
              
               style={{
                padding: '12px',
                display: 'flex',
                flexDirection:'column',
                justifyContent: 'center',
                alignItems: 'center',
                opacity: 1,
                transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
                width: '100%',
                paddingLeft: '0'
              }}>
                <MdHistory 
                onClick={toggleSidebar}
                  size={leftWidth === '15%' ? 28 : 24} 
                  style={{ 
                    transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
                    transform: 'rotate(360deg)',
                    flexShrink: 0
                  }} 
                />

                <TiStarFullOutline
                onClick={toggleSidebar}
                  size={leftWidth === '15%' ? 28 : 24} 
                  style={{ 
                    transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
                    transform: 'rotate(0deg)',
                    flexShrink: 0,
                    color:'#3E325C',
                  }} 
                />
              </div>
            )}
          </div>
      </div>
    </>
  );
};

export default Sidebar;
