import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { GoLock, GoMail } from "react-icons/go";
import { PiSignOutLight } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import './Signup.css'
import { LuUser2 } from "react-icons/lu";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import axios from 'axios';
import { baseEndpoint } from "../../API/endpoints";

const Signup = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState({ text: '', type: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    full_name: '',
    email: '',
    password: '',
    confirmpassword: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage({ text: '', type: '' });

    try {
      const { full_name, email, password, confirmpassword } = formData;
      
      // Validate passwords match
      if (password !== confirmpassword) {
        setMessage({ text: 'Passwords do not match', type: 'error' });
        setIsLoading(false);
        return;
      }

      const response = await axios.post(`${baseEndpoint}/userauth/register`, {
        full_name,
        email,
        password
      });
      
      setMessage({ text: 'Registration successful! Redirecting to login...', type: 'success' });
      setTimeout(() => {
        navigate('/signin');
      }, 2000);
      
    } catch (error) {
      setMessage({ 
        text: error.response?.data?.detail || 
              error.response?.data?.message || 
              error.response?.data?.error || 
              'Registration failed. Please try again.', 
        type: 'error' 
      });
    } finally {
      setIsLoading(false);
    }
  };

  const calculateStrength = (password) => {
    const lengthCriteria = password.length >= 8;
    const numberCriteria = /\d/.test(password);
    const specialCharCriteria = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const upperCaseCriteria = /[A-Z]/.test(password);

    
    if (password.length === 0) return { strength: 'Low', color: 'red' };

    if (lengthCriteria && numberCriteria && specialCharCriteria && upperCaseCriteria) {
      return { strength: 'Strong', color: 'green' };
    }

    if (lengthCriteria && (numberCriteria || specialCharCriteria) && upperCaseCriteria) {
      return { strength: 'Good', color: 'orange' };
    }

    if (lengthCriteria && (numberCriteria || specialCharCriteria || upperCaseCriteria)) {
      return { strength: 'Medium', color: 'yellow' };
    }

    // Low password: Too short or lacks variety
    return { strength: 'Low', color: 'red' };
  };

  const { strength, color } = calculateStrength(formData.password);
  return (
    <div 
      className="base d-flex flex-column align-items-center justify-content-center "
    >
      <div 
        className="box1 d-flex flex-column align-items-center justify-content-start"
      >
        <div className="imgbox d-flex align-items-center justify-content-center  " 
        >
          <div
            className="d-flex align-items-center justify-content-center me-2"
          >
            <img
              src="/images/Logo.svg"
              alt=""
             
            />
          </div>
          <img 
            src="/images/Group 10.svg" alt=""
          />
        </div>
        <p className="heading">
          Sign up
        </p>
        <form className="signupform" onSubmit={handleSubmit}>
          <div className="formbox">
            <label className="username" htmlFor="full_name">Full Name</label>
            <div className="input-container">
              <LuUser2 className="icon" />
              <input 
                className="inputbox" 
                name="full_name" 
                onChange={handleChange} 
                type="text" 
                id="full_name" 
                placeholder="Enter your full name"
                value={formData.full_name} 
              />
            </div>
          </div>
          <div className="formbox">
            <label className="emaillabel" htmlFor="email">Email Address</label>
            <div className="input-container">
              <GoMail className="icon" />
              <input 
                className="inputbox" 
                name="email" 
                onChange={handleChange} 
                type="email" 
                id="email" 
                placeholder="example@gmail.com"
                value={formData.email} 
              />
            </div>
          </div>
          <div className="formbox">
            <label className="password" htmlFor="password">Password</label>
            <div className="input-container" style={{ position: 'relative' }}>
              <GoLock className="icon" />
              <input
                className="inputbox"
                name="password"
                onChange={handleChange}
                type={showPassword ? "text" : "password"}
                id="password"
                placeholder="Enter your password"
                value={formData.password}
              />
              <span
                onClick={() => setShowPassword(!showPassword)}
                className="password-toggle-icon"
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: '20px',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer',
                }}
              >
                {showPassword ? <FaEye /> : <FaEyeSlash />}
              </span>
            </div>

            <div className="password-strength" style={{ marginTop: '10px' }}>
              <div style={{
                width: '23%',
                height: '4px',
                backgroundColor: color === 'green' || color === 'red' || color === 'orange'|| color === 'yellow' ? color : '#CBD5E1', // Show red if color is red or orange
                borderRadius: '2px',
                marginTop: '5px',
                position: 'relative',
              }}></div>

              <div style={{
                width: '23%',
                height: '4px',
                backgroundColor: color === 'yellow' || color === 'orange'|| color === 'green' ? color : '#CBD5E1', // Show yellow if color is yellow, red, or orange
                borderRadius: '2px',
                marginTop: '5px',
                position: 'relative',
              }}></div>

              <div style={{
                width: '23%',
                height: '4px',
                backgroundColor: color === 'orange' || color === 'green' ? color : '#CBD5E1', // Show orange if color is orange or red
                borderRadius: '2px',
                marginTop: '5px',
                position: 'relative',
              }}></div>

              <div style={{
                width: '23%',
                height: '4px',
                backgroundColor: color === 'green'  ? color : '#CBD5E1', // Show green if color is green, red, or orange
                borderRadius: '2px',
                marginTop: '5px',
                position: 'relative',
              }}></div>

            </div>
            <div >
              <div className="passwordtext" style={{ fontWeight: 'bold' }}>
                Password strength:
                {strength}  
              </div>
            </div>  
          </div>
          <div className="formbox">
            <label className="cmpassword" htmlFor="confirmpassword">Confirm Password</label>
            <div className="input-container">
              <GoLock className="icon"/>
              <input 
                className="inputbox" 
                id="confirmpassword" 
                name="confirmpassword"  
                type={showPassword ? 'text' : 'password'} 
                onChange={handleChange} 
                value={formData.confirmpassword}
                placeholder="*****************" 
              />
              <span
                onClick={() => setShowPassword(!showPassword)}
                className="password-toggle-icon"
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: '20px',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer',
                }}
              >
                {showPassword ? <FaEye /> : <FaEyeSlash />}
              </span>
            </div>
          </div>
          <button 
            type="submit"
            className="submit-button"
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <AiOutlineLoading3Quarters className="loading-icon spin" />
                <span>Creating Account...</span>
              </>
            ) : (
              <>
                <span>Sign Up</span>
                <PiSignOutLight />
              </>
            )}
          </button>
          {message.text && (
            <div className={`message ${message.type}`}>
              {message.text}
            </div>
          )}
          <div className="signin-link">
            Already have an account? <a href="/signin" className="ms-2" style={{textDecoration:'none'}}>Sign In.</a>
          </div>
        </form>
        
      </div>
      <div className="copybox d-flex align-items-center justify-content-center">
        <p className="copytext">Copyright 2024 Transpoze</p>
      </div>
    </div>
  );
};

export default Signup;