import React, { useState, useEffect } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { GoLock } from "react-icons/go";
import { useNavigate, useLocation } from "react-router-dom";
import { baseEndpoint } from "../../API/endpoints";
import axios from "axios";
import './Setpassword.css'

const Setpassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
  });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isTokenValid, setIsTokenValid] = useState(false);

  // Get token from URL
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');

  useEffect(() => {
    // Verify token when component mounts
    const verifyToken = async () => {
      if (!token) {
        setError("No reset token provided");
        setTimeout(() => {
          navigate('/forgotpassword');
        }, 3000);
        return;
      }

      try {
        const response = await axios.get(`${baseEndpoint}/userauth/verify-reset-token/${token}`);
        setIsTokenValid(true);
      } catch (err) {
        setError("Invalid or expired reset token");
        setTimeout(() => {
          navigate('/forgotpassword');
        }, 3000);
      }
    };

    verifyToken();
  }, [token, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Only validate that passwords match
    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    setIsLoading(true);
    try {
      await axios.post(`${baseEndpoint}/userauth/reset-password`, {
        token: token,
        new_password: formData.password
      });
      
      setError("");
      setIsSuccess(true);
      setTimeout(() => {
        navigate('/signin');
      }, 3000);
    } catch (err) {
      setError(err.response?.data?.detail || "Failed to reset password. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const calculateStrength = (password) => {
    const lengthCriteria = password.length >= 8;
    const numberCriteria = /\d/.test(password);
    const specialCharCriteria = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const upperCaseCriteria = /[A-Z]/.test(password);

    
    if (password.length === 0) return { strength: 'Low', color: 'red' };

    if (lengthCriteria && numberCriteria && specialCharCriteria && upperCaseCriteria) {
      return { strength: 'Strong', color: 'green' };
    }

    if (lengthCriteria && (numberCriteria || specialCharCriteria) && upperCaseCriteria) {
      return { strength: 'Good', color: 'orange' };
    }

    if (lengthCriteria && (numberCriteria || specialCharCriteria || upperCaseCriteria)) {
      return { strength: 'Medium', color: 'yellow' };
    }

    // Low password: Too short or lacks variety
    return { strength: 'Low', color: 'red' };
  };

  const { strength, color } = calculateStrength(formData.password);

  return (
    <div 
      className="base d-flex flex-column align-items-center justify-content-center "
    >
      <div 
        className="box1 d-flex flex-column align-items-center justify-content-start"
      >
        {isSuccess ? (
          <div className="success-message d-flex flex-column align-items-center justify-content-center">
            <div className="success-icon mb-3">
              <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="32" cy="32" r="32" fill="#4CAF50" fillOpacity="0.1"/>
                <path d="M20 32L28 40L44 24" stroke="#4CAF50" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
            <h2 className="success-title mb-2">Password Reset Successful!</h2>
            <p className="success-text text-center mb-3">
              Your password has been successfully updated. Redirecting to login...
            </p>
          </div>
        ) : (
          <>
            <p className="heading">
              Reset Your Password
            </p>
            <form onSubmit={handleSubmit} className="setpasswordform">
              {error && (
                <div className="error-message" 
                  style={{ 
                    color: '#dc3545', 
                    backgroundColor: '#f8d7da',
                    padding: '10px',
                    borderRadius: '4px',
                    marginBottom: '15px',
                    textAlign: 'center',
                    width: '100%'
                  }}
                >
                  {error}
                </div>
              )}
              <div className="setpasswordformbox">
                <label className="password" htmlFor="password"> Password</label>
                <div className="input-container" style={{ position: 'relative' }}>
                  <GoLock className="icon" />
                  <input
                    className="inputbox"
                    id="password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    onChange={handleChange}
                    placeholder="*****************"
                  />
                  <span
                    onClick={() => setShowPassword(!showPassword)}
                    className="password-toggle-icon"
                    style={{
                      position: 'absolute',
                      top: '50%',
                      right: '20px',
                      transform: 'translateY(-50%)',
                      cursor: 'pointer',
                    }}
                  >
                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                  </span>
                </div>

                <div className="password-strength" style={{ marginTop: '10px' }}>
                  <div style={{
                    width: '23%',
                    height: '4px',
                    backgroundColor: color === 'green' || color === 'red' || color === 'orange'|| color === 'yellow' ? 'red' : '#CBD5E1', // Show red if color is red or orange
                    borderRadius: '2px',
                    marginTop: '5px',
                    position: 'relative',
                  }}></div>

                  <div style={{
                    width: '23%',
                    height: '4px',
                    backgroundColor: color === 'yellow' || color === 'orange'|| color === 'green' ? 'yellow' : '#CBD5E1', // Show yellow if color is yellow, red, or orange
                    borderRadius: '2px',
                    marginTop: '5px',
                    position: 'relative',
                  }}></div>

                  <div style={{
                    width: '23%',
                    height: '4px',
                    backgroundColor: color === 'orange' || color === 'green' ? 'orange' : '#CBD5E1', // Show orange if color is orange or red
                    borderRadius: '2px',
                    marginTop: '5px',
                    position: 'relative',
                  }}></div>

                  <div style={{
                    width: '23%',
                    height: '4px',
                    backgroundColor: color === 'green'  ? 'green' : '#CBD5E1', // Show green if color is green, red, or orange
                    borderRadius: '2px',
                    marginTop: '5px',
                    position: 'relative',
                  }}></div>

                </div>
                <div >
                  <div className="passwordtext" style={{ fontWeight: 'bold' }}>
                    Password strength:
                    {strength}  
                  </div>
                </div>  
              </div>
              <div className="setpasswordformbox">
                <label className="cmpassword" htmlFor="confirmpassword"> Confirm Password</label>
                <div className="input-container">
                  <GoLock className="icon"/>
                  <input className="inputbox" id="cmpassword" name="confirmPassword"  type={showPassword ? 'text' : 'password'} onChange={handleChange} placeholder="*****************" />
                  <span
                    onClick={() => setShowPassword(!showPassword)}
                    
                    className="password-toggle-icon"
                    style={{
                      position: 'absolute',
                      top: '50%',
                      right: '20px',
                      transform: 'translateY(-50%)',
                      cursor: 'pointer',
                    }}
                  >
                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                  </span>
                </div>
              </div>
              <button className="submit" type="submit" disabled={isLoading || !isTokenValid}>
                {isLoading ? "Updating..." : "Update Password"}
                <GoLock style={{fontSize:'26px'}}/>
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};
export default Setpassword; 