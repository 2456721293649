import React, { useEffect, useState, useRef, useId, useContext } from "react";
import { GrCopy } from "react-icons/gr";
import axios from "axios";
import jsPDF from "jspdf";
import { LuDownload } from "react-icons/lu";
import { BsThreeDotsVertical } from "react-icons/bs";
import '.././Mcq.css'


import { useTheme } from '@mui/material/styles';
import { MdRefresh } from "react-icons/md";
import { baseEndpoint } from "../../API/endpoints";
import Formpage from "../Mainform/Rightsideform/Form";
import { useLocation, useNavigate } from "react-router-dom";
import { CiBookmark } from "react-icons/ci";
import { IoBookmark } from "react-icons/io5";

const Assignments=()=>{
    const [error, setError] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const handleSwitchChange = (event) => {
        setShowAnswers(event.target.checked); // Update state based on switch
    };
    const anchorRef = useRef(null);
    const [showRecentActivity, setShowRecentActivity] = useState(true);
    
    const [questions, setQuestions] = useState([]);
    const [variantQuestions, setVariantQuestions] = useState([]);
    const [anchor, setAnchor] = React.useState(null);
    const [questionsHeading, setquestionsHeading] = useState(null);
    console.log(questions,'it will be ol')
    const [hasRefreshed, setHasRefreshed] = useState(false);
    const [assignmentResponseData, setassignmentResponseData] = useState([]);
    console.log(assignmentResponseData,'assignmentResponseData ].;;.;/.,;./,')
    const storeData = (id, userId, response) => {
        setassignmentResponseData(prevData => ({
          ...prevData,
          [`${id}_${userId}`]: {
            id,
            response,
            userId
          }
        }));
      };

      
      useEffect(() => {
        if (assignmentResponseData) {
            const keys = Object.keys(assignmentResponseData);
            if (keys.length > 0) {
                const lastKey = keys[keys.length - 1];
                const { userId, id } = assignmentResponseData[lastKey];
                setLastUserId(userId);
                setLastId(id);
            }
        }
    }, [assignmentResponseData]);

    const [loading, setLoading] = useState(false); 
    const [loading1, setLoading1] = useState(false); 

   

    // const [selectedOptions, setSelectedOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');

    // const handleChange = (event, newValue) => {
    //     // Ensure newValue is an array
    //     if (Array.isArray(newValue)) {
    //         setSelectedOptions(newValue);
    //     }
    // };

    // const handleInputChange = (event, newInputValue) => {
    //     setInputValue(newInputValue);
    // };

    // const handleDelete = (chipToDelete) => () => {
    //     setSelectedOptions((prevOptions) =>
    //         prevOptions.filter((option) => option !== chipToDelete)
    //     );
    // };


    // chips for topic
    const [showMoreChips, setShowMoreChips] = useState(false);

    const toggleShowMoreChips = (event) => {
        setAnchor(anchor ? null : event.currentTarget);
        setShowMoreChips(!showMoreChips);
      };

      const [showMoreChips1, setShowMoreChips1] = useState(false);

    const toggleShowMoreChips1 = (event) => {
        setAnchor(anchor ? null : event.currentTarget);
        setShowMoreChips1(!showMoreChips1);
      };

      const { 
        selectedClass, 
        selectedSubject, 
        selectedTitle, 
        selectedChapters, 
        numberOfQuestions: initQuestions, 
        difficulty: initDifficulty, 
        autoSubmit,
        q_type 
    } = location.state || {};
    const [classValue, setClassValue] = useState('');
    const [subjectValue, setSubjectValue] = useState('');
    const [chapterValues, setChapterValues] = useState([]);
    const [topicValues, setTopicValues] = useState([]);
    const [numberOfQuestions, setNumberOfQuestions] = useState(initQuestions || '1'); // Default value set to 10
    const [difficulty, setDifficulty] = useState('Medium'); 
    const [titleValue, setTitleValue] = useState('');
    const [classData, setClassData] = useState([]);
    const [subjectOptions, setSubjectOptions] = useState([]);
    const [titleOptions, setTitleOptions] = useState([]);
    const [chapterOptions, setChapterOptions] = useState([]);
    const [qtypeValue, setQtypeValue] = useState('short answers');
    const [lastUserId, setLastUserId] = useState(null);
    const [lastId, setLastId] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const hasSubmitted = useRef(false);
    const [isstarred, setStarred]=useState(false)
    const [isregenareteshow,setIsregenareteshow]=useState(false)
    const [ishistory, setIshistory]=useState(false)

    useEffect(() => {
        // Simulate fetching data from the backend
        fetchDataFromBackend();
      }, []);

      const fetchDataFromBackend = async () => {
        try {
            // const response = await fetch('http://localhost:8080/dataset/dataset_access');

            const response = await fetch(`${baseEndpoint}/dataset/dataset_access`)
            const data = await response.json();
            console.log('Fetched data:', data); // Log the data to check its structure
            setClassData(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const qid =''
    console.log(qid," iasdhiofhaspdifhosdhYBUNR EBNI")

    // Convert the array to a string if needed
    const topicString = topicValues.join(', ');

    // Convert the string back to an array for the Autocomplete component
    const topicArrayForAutocomplete = topicString.split(',').filter(Boolean);
   
    const [formData, setFormData] = useState(null);
    const [sideFormData, setSideFormData] = useState({
        number: '10',
        complexity: 'medium'
    });
    const [hoveredQuestion, setHoveredQuestion] = useState(null);
    const [showDropdown, setShowDropdown] = useState(null);

    const handleReportandDelete = async (index,qIndex,status)=>{
        setQuestions(prevQuestions => {
            const updatedQuestions = [...prevQuestions];  // Copy the current state
            updatedQuestions[index][qIndex].state = status;  // Update the status to 'deleted' for the selected question
            return updatedQuestions;  // Set the updated state
          });

          try {
            const userDetail = JSON.parse(localStorage.getItem('userdetail')) || JSON.parse(localStorage.getItem('userdata'));
            const user_id = userDetail?.id;
            const rid = assignmentResponseData[index][0].id
            const endpoint = `${baseEndpoint}/editable/users/${user_id}/responses/${rid}/flagquestion`;
        
            const response = await axios({
              method: 'post',
              url: endpoint,
              data:{
                index_number : qIndex,
                state : status,
                reason:'',
              }
            });
        
            const data = response.data; 
            console.log(data, "it is the new response data");
        
          } catch (error) {
            console.error('Error:', error);
          }

    }
    const handleQuestionAction = (action, groupIndex, questionIndex) => {
        switch(action) {
            case 'delete':
                handleReportandDelete(groupIndex,questionIndex,"deleted")
                console.log('Deleting question:', groupIndex, questionIndex);
                break;
            case 'report':
                handleReportandDelete(groupIndex,questionIndex,"reported")
                console.log('Reporting question:', groupIndex, questionIndex);
                break;
            default:
                break;
        }
        setShowDropdown(null);
    };

    useEffect(() => {
        if (location.state) {
            const mainFormData = {
                standard: location.state.selectedClass,
                subject: location.state.selectedSubject,
                topic: '',
                file_name: location.state.selectedChapters,
                title: location.state.selectedTitle,
                q_type: location.state.q_type
            };
            setFormData(mainFormData);
        }
    }, [location.state]);
    

    const [showAnswers, setShowAnswers] = useState(false);
    const handleAnswersToggle = (checked) => {
        setShowAnswers(checked);
    };
    useEffect(() => {
        if (autoSubmit && !hasSubmitted.current && 
            selectedClass && selectedSubject && selectedTitle && 
            selectedChapters?.length > 0 && !loading) {
            hasSubmitted.current = true;
            handleSubmit({
                standard: selectedClass,
                subject: selectedSubject,
                topic: '',
                file_name: selectedChapters,
                number: initQuestions,
                complexity: initDifficulty,
                title: selectedTitle,
                q_type: q_type || 'Short Answers'
            });
        }
    }, [selectedClass, selectedSubject, selectedTitle, selectedChapters, loading]);

    const handleSubmit = async (formData) => {
        if (isSubmitting) return;
        setIsSubmitting(true);
        setLoading(true);
        setError(null);
        setSideFormData(formData);
        setStarred(false)
        setIshistory(false)
        setIsregenareteshow(true)
        setQuestions([]);
        setassignmentResponseData([]); 
        const accessToken = localStorage.getItem('access_token');
        const googleToken = localStorage.getItem('token');
    
        if (!accessToken && !googleToken) {
            alert('Login to generate content');
            console.error('No access token or Google token found, redirecting to login...');
            window.location.href = '/signin';
            return;
        }
    
        const userDetail = JSON.parse(localStorage.getItem('userdetail')) || JSON.parse(localStorage.getItem('userdata'));
        const user_id = userDetail?.id;
    
        const endpoint = `${baseEndpoint}/assignment/${user_id}/assignment_stream`;
        const body = {
            standard: formData.standard,
            subject: formData.subject,
            topic: formData.topic || '',
            file_name: formData.file_name,
            number: formData.number,
            complexity: formData.complexity,
            title: formData.title,
            q_type: 'Short Answers'  // Set consistent q_type for assignments
        };
        setquestionsHeading(body);
        let currentQuestions = [];
        let responseData = [];
    
        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            });
    
            const reader = response.body.getReader();
            const decoder = new TextDecoder("utf-8");
            let buffer = '';
            const stopIdentifier = '3e6c447a3f45797db5b4b66cc99c412ff27a7b62efcd8ba7c8b482add861ed5a3d89a2828be5d201e5d7bd4eddbff3a2c3a58d62ba6278be1e5bfcab377dc2fd';
    
            while (true) {
                const { done, value } = await reader.read();
                if (done) break;
    
                buffer += decoder.decode(value, { stream: true });
                console.log('Current buffer:', buffer);
    
                // Check for stop identifier in the buffer
                if (buffer.includes(stopIdentifier)) {
                    console.log('Stopping streaming due to identifier match.');
                    break;
                }
    
                // Process complete JSON objects in the buffer
                while (true) {
                    const startIndex = buffer.indexOf('{');
                    const endIndex = buffer.indexOf('}', startIndex) + 1;
    
                    if (startIndex === -1 || endIndex === -1) break;
    
                    const jsonString = buffer.slice(startIndex, endIndex);
                    buffer = buffer.slice(endIndex);
    
                    try {
                        const parsedData = JSON.parse(jsonString);
                        console.log(parsedData, 'Parsed data');
    
                        // Check if message is an error and should trigger a retry
                        if (parsedData.msg && parsedData.msg === "OpenAi could not generate response, Please Try again" && 3 > 0) {
                            console.log('Received retry message, retrying...');
                            setLoading(true);  // Reset loading state if retrying
                            await new Promise(resolve => setTimeout(resolve, 2000)); // Wait for 2 seconds before retrying
                            await handleSubmit(formData);  // Call the function again, decrementing retry count
                            return;  // Exit current function execution to avoid additional processing
                        }
    
                        // Only process valid question data
                        if (!parsedData.msg || parsedData.msg !== "OpenAi could not generate response, Please Try again") {
                            if (parsedData.userId) {
                                responseData.push(parsedData);
                                console.log(responseData, "All response data");
    
                                setassignmentResponseData(prevQuestions => {
                                    const newQuestions = [...prevQuestions];
    
                                    // Handle question grouping
                                    if (newQuestions.length === 0 || newQuestions[newQuestions.length - 1].length > 0) {
                                        newQuestions.push([parsedData]);
                                    } else {
                                        newQuestions[newQuestions.length - 1].push(parsedData);
                                    }
    
                                    return newQuestions;
                                });
                            } else {
                                currentQuestions.push(parsedData);
                                console.log(currentQuestions, 'Current questions');
    
                                setLoading(false);
                                setQuestions(prevQuestions => {
                                    const newQuestions = [...prevQuestions];
    
                                    // Check if parsedData already exists in the existing chunks
                                    const isDuplicate = newQuestions.some(chunk => chunk.includes(parsedData));
    
                                    // If no duplicate found, add the parsedData
                                    if (!isDuplicate) {
                                        if (newQuestions.length === 0 || newQuestions[newQuestions.length - 1].length >= formData.number) {
                                            newQuestions.push([parsedData]);
                                        } else {
                                            newQuestions[newQuestions.length - 1].push(parsedData);
                                        }
                                    }
    
                                    return newQuestions;
                                });
                            }
    
                            // If response contains stringified JSON in `response`, parse it
                            if (parsedData.response) {
                                const responseQuestions = JSON.parse(parsedData.response.replace(/'/g, '"'));
                                console.log(responseQuestions, 'Parsed response questions');
    
                                responseQuestions.forEach(question => {
                                    currentQuestions.push(question);
                                });
                            }
                        }
                    } catch (parseError) {
                        console.error('Error parsing JSON chunk:', parseError);
                        console.log('Malformed JSON string:', jsonString);
                    }
                }
            }
        } catch (err) {
            console.error('Error fetching data:', err);
            setError('An error occurred while fetching questions.');
        } finally {
            setLoading(false);
            setIsSubmitting(false);
        }
        setIsregenareteshow(false)
    };
    const renderQuestions = () => {
      return questions.map((group, index) => (
        <div key={index} className=" renderquestion d-flex aling-items-center justify-content-center">
          {index > 0 && (
                    <h4 >
                       Variant
                    </h4>
                )}
          <div className="rederbox1">
          {group.map((questionObj, qIndex) => {
                    // Skip rendering question if its status is 'deleted' or 'reported'
                    if (questionObj.state == "deleted" || questionObj.state == "reported") {
                        return null; // Do not render the question
                    }else{
                        return (
                            <div 
                                key={qIndex} 
                                style={{
                                    position: 'relative',
                                    fontSize: '1rem',
                                    fontWeight: '400',
                                    padding: '10px',
                                    borderRadius: '5px',
                                }}
                            >
                                <div 
                                    onMouseEnter={() => setHoveredQuestion(`${index}-${qIndex}`)}
                                    onMouseLeave={() => {
                                        if (!showDropdown) {
                                            setHoveredQuestion(null);
                                        }
                                    }}
                                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', position: 'relative' }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'flex-start', maxWidth: '95%' }}>
                                        <span>{qIndex + 1}. {questionObj.question}</span>
                                        <div 
                                            style={{ position: 'relative', display: 'inline-block', marginLeft: '10px' }}
                                            onMouseEnter={(e) => {
                                                setShowDropdown(`${index}-${qIndex}`);
                                                const button = e.currentTarget.querySelector('button');
                                                if (button) {
                                                    button.style.backgroundColor = '#f5f5f5';
                                                    const icon = button.querySelector('svg');
                                                    if (icon) icon.style.color = '#666666';
                                                }
                                            }}
                                            onMouseLeave={(e) => {
                                                setShowDropdown(null);
                                                const button = e.currentTarget.querySelector('button');
                                                if (button) {
                                                    button.style.backgroundColor = 'transparent';
                                                    const icon = button.querySelector('svg');
                                                    if (icon) icon.style.color = '#C0C0C0';
                                                }
                                            }}
                                        >
                                            <button
                                                style={{
                                                    backgroundColor: 'transparent',
                                                    border: 'none',
                                                    borderRadius: '5px',
                                                    cursor: 'pointer',
                                                    padding: '3px 6px',
                                                    opacity: hoveredQuestion === `${index}-${qIndex}` ? 1 : 0,
                                                    transition: 'all 0.2s ease',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <BsThreeDotsVertical style={{ 
                                                    color: '#C0C0C0',
                                                    transition: 'color 0.2s ease',
                                                    fontSize: '16px',
                                                }} />
                                            </button>
                                            {showDropdown === `${index}-${qIndex}` && (
                                                <div 
                                                    style={{
                                                        position: 'absolute',
                                                        left: '100%',
                                                        top: '0',
                                                        backgroundColor: 'white',
                                                        boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                                                        borderRadius: '5px',
                                                        zIndex: 1000,
                                                        minWidth: '180px',
                                                        border: '1px solid #eee',
                                                    }}
                                                >
                                                    <div 
                                                        onClick={(event) => handleRefreshQuestion(event, qIndex, index)}
                                                        style={{
                                                            padding: '8px 15px', 
                                                            cursor: 'pointer',
                                                            borderBottom: '1px solid #eee',
                                                            transition: 'background-color 0.2s',
                                                        }}
                                                        onMouseEnter={(e) => e.target.style.backgroundColor = '#f5f5f5'}
                                                        onMouseLeave={(e) => e.target.style.backgroundColor = 'white'}
                                                    >
                                                        Regenerate Question
                                                    </div>
                                                    <div 
                                                        onClick={() => handleQuestionAction('delete', index, qIndex)}
                                                        style={{
                                                            padding: '8px 15px', 
                                                            cursor: 'pointer',
                                                            borderBottom: '1px solid #eee',
                                                            transition: 'background-color 0.2s',
                                                        }}
                                                        onMouseEnter={(e) => e.target.style.backgroundColor = '#f5f5f5'}
                                                        onMouseLeave={(e) => e.target.style.backgroundColor = 'white'}
                                                    >
                                                        Delete Question
                                                    </div>
                                                    <div 
                                                        onClick={() => handleQuestionAction('report', index, qIndex)}
                                                        style={{
                                                            padding: '8px 15px', 
                                                            cursor: 'pointer',
                                                            transition: 'background-color 0.2s',
                                                        }}
                                                        onMouseEnter={(e) => e.target.style.backgroundColor = '#f5f5f5'}
                                                        onMouseLeave={(e) => e.target.style.backgroundColor = 'white'}
                                                    >
                                                        Report Question
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {showAnswers && <li style={{ fontWeight: '800', maxWidth: '95%' }}>Answer: {questionObj.answer}</li>}
                                </div>
                            </div>
                        );
                    }
                })}
          </div>
          <div style={{width:'50%',display:'flex',alignItems:"start",justifyContent:'start',marginRight:'5%'}}>
                <div className="footer-left">
                    <b onClick={() => copyToClipboard(index)} className="footer-icon">
                        <GrCopy />
                    </b>
                    <b onClick={() => generatePDF(questions[index], questionsHeading)} className="footer-icon">
                        <LuDownload />
                    </b>
                    <b 
                    onClick={() => handlesave(index)} 
                    className="footer-icon"
                    >
                        {isstarred
                            ? <IoBookmark />
                            : <CiBookmark />
                        }
                    </b>
                
                </div>
              </div>
        </div>
      ));
    };
    
    const handleRefreshQuestion = async (event, index,mcid) => {
      event.preventDefault();
      const accessToken = localStorage.getItem('access_token');
      const googleToken = localStorage.getItem('token');
  
      if (!accessToken && !googleToken) {
          alert('Login to generate content');
          console.error('No access token or Google token found, redirecting to login...');
          window.location.href = '/signin';
          return;
      }
  
      const item = assignmentResponseData[mcid][0]; // Assuming you want the first item
      console.log(item,"it the all data to get usid rid")
      const { userId, id } = item; // Destructure userId and id

      console.log("User ID:",item.userId);
      console.log("ID:", item.id);
    //   const endpoint = `http://localhost:8080/editable/users/${userId}/responses/${id}/edit`;
      const endpoint = `${baseEndpoint}/editable/users/${userId}/responses/${id}/edit `
  
      const method = 'POST';
      const sendingdata = {
          index_number: index,
      };
  
      try {
          const response = await axios({
              method: method,
              url: endpoint,
              data: sendingdata,
          });
  
          console.log(response.data, "Fetched single question data");
  
  
          
          if (questions[mcid]) {
            const newQuestionData = response.data.response; // This should be the new question object
        
            // Update the state to replace the specific question
            setQuestions(prevQuestions => {
                return prevQuestions.map((innerArray, innerIndex) => {
                    // Check if we are in the right inner array
                    if (innerIndex === mcid) {
                        return innerArray.map((question, questionIndex) => {
                            // Replace the specific question object at questionIndex
                            if (questionIndex === index) {
                                return { ...question, ...newQuestionData }; // Replace with new data
                            }
                            return question; // Keep the existing question
                        });
                    }
                    return innerArray; // Keep the existing inner array
                });
            });
        } else {
            console.error("ID and User ID do not match.");
        }
      } catch (error) { 
          console.error('Error fetching data:', error);
      } finally {
          setLoading(false); 
      }
  };
  
  
  const handleVariant = async (event, retryCount = 3) => {
    event.preventDefault();
    setIsregenareteshow(true)
    setStarred(false)
    setLoading1(true);
    const accessToken = localStorage.getItem('access_token');
    const googleToken = localStorage.getItem('token');

    if (!accessToken && !googleToken) {
        alert('Login to generate content');
        console.error('No access token or Google token found, redirecting to login...');
        window.location.href = '/signin';
        return;
    }

    if (!assignmentResponseData || assignmentResponseData.length === 0) {
        console.error('No previous response data available');
        setLoading1(false);
        return;
    }

    const lastIndex = assignmentResponseData.length - 1;
    const item = assignmentResponseData[lastIndex][0];
    
    if (!item || !item.userId || !item.id) {
        console.error('Invalid response data');
        setLoading1(false);
        return;
    }

    const user_id = item.userId;
    const response_id = item.id;

    // Determine which data to use based on availability
    let sendingData;
    if (sideFormData) {
        sendingData = {
            standard: sideFormData.standard,
            subject: sideFormData.subject,
            topic: sideFormData.topic || '',
            file_name: sideFormData.file_name,
            title: sideFormData.title,
            number: sideFormData.number,
            complexity: sideFormData.complexity,
            q_type:sideFormData.q_type,
        };
        
    } else if ( formData) {
        // If main form data is available, use it as base
        sendingData = {
            standard: formData.standard,
            subject: formData.subject,
            topic: formData.topic || '',
            file_name: formData.file_name,
            title: formData.title,
            number:10,
            complexity: 'medium'
        };
    } else {
        console.error('No form data available');
        setLoading1(false);
        return;
    }

    console.log('Sending data to backend:', sendingData);

    const endpoint = `${baseEndpoint}/assignment/users/${user_id}/responses/${response_id}/variants_stream`;
    let currentQuestions = [];
    let responseData = []; // Array to hold response data with userId and other info
    try {
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(sendingData),
        });

        const reader = response.body.getReader();
        const decoder = new TextDecoder("utf-8");
        let buffer = '';
        const stopIdentifier = '3e6c447a3f45797db5b4b66cc99c412ff27a7b62efcd8ba7c8b482add861ed5a3d89a2828be5d201e5d7bd4eddbff3a2c3a58d62ba6278be1e5bfcab377dc2fd';

        while (true) {
            const { done, value } = await reader.read();
            if (done) break;

            buffer += decoder.decode(value, { stream: true });
            console.log('Current buffer:', buffer);

            // Check for stop identifier in the buffer
            if (buffer.includes(stopIdentifier)) {
                console.log('Stopping streaming due to identifier match.');
                break;
            }

            // Process complete JSON objects in the buffer
            while (true) {
                const startIndex = buffer.indexOf('{');
                const endIndex = buffer.indexOf('}', startIndex) + 1;

                if (startIndex === -1 || endIndex === -1) break;

                const jsonString = buffer.slice(startIndex, endIndex);
                buffer = buffer.slice(endIndex);

                try {
                    const parsedData = JSON.parse(jsonString);
                    console.log(parsedData, 'Parsed data');

                    // Check if message is an error and should trigger a retry
                    if (parsedData.msg && parsedData.msg === "OpenAi could not generate response, Please Try again" && 3 > 0) {
                        console.log('Received retry message, retrying...');
                        setLoading(true);  // Reset loading state if retrying
                        await new Promise(resolve => setTimeout(resolve, 2000)); // Wait for 2 seconds before retrying
                        await handleSubmit(formData);  // Call the function again, decrementing retry count
                        return;  // Exit current function execution to avoid additional processing
                    }// Only process valid question data
                    if (!parsedData.msg || parsedData.msg !== "OpenAi could not generate response, Please Try again") {
                        if (parsedData.userId) {
                            responseData.push(parsedData);
                            console.log(responseData, "All response data");

                            setassignmentResponseData(prevQuestions => {
                                const newQuestions = [...prevQuestions];

                                // Handle question grouping
                                if (newQuestions.length === 0 || newQuestions[newQuestions.length - 1].length > 0) {
                                    newQuestions.push([parsedData]);
                                } else {
                                    newQuestions[newQuestions.length - 1].push(parsedData);
                                }

                                return newQuestions;
                            });
                        } else {
                            currentQuestions.push(parsedData);
                            console.log(currentQuestions, 'Current questions');

                            setLoading(false);
                            setQuestions(prevQuestions => {
                                const newQuestions = [...prevQuestions];

                                // Check if parsedData already exists in the existing chunks
                                const isDuplicate = newQuestions.some(chunk => chunk.includes(parsedData));

                                // If no duplicate found, add the parsedData
                                if (!isDuplicate) {
                                    if (newQuestions.length === 0 || newQuestions[newQuestions.length - 1].length >= numberOfQuestions) {
                                        newQuestions.push([parsedData]);
                                    } else {
                                        newQuestions[newQuestions.length - 1].push(parsedData);
                                    }
                                }

                                return newQuestions;
                            });
                        }

                        // If response contains stringified JSON in `response`, parse it
                        if (parsedData.response) {
                            const responseQuestions = JSON.parse(parsedData.response.replace(/'/g, '"'));
                            console.log(responseQuestions, 'Parsed response questions');

                            responseQuestions.forEach(question => {
                                currentQuestions.push(question);
                            });
                        }
                    }
                } catch (parseError) {
                    console.error('Error parsing JSON chunk:', parseError);
                    console.log('Malformed JSON string:', jsonString);
                }
            }
        }
    } catch (err) {
        console.error('Error fetching data:', err);
        setError('An error occurred while fetching questions.');
    } finally {
        setLoading(false);
        setIsSubmitting(false);
    }
    setIsregenareteshow(false)
};

const handlesave = async (tostarvalue) => {
    try {
        console.log(tostarvalue,"it is the index value")
      const tostar = assignmentResponseData[tostarvalue];
      console.log(tostar, 'it is the data to be started');
    //   console.log(tostar.id,'it is normal ',tostar[tostarvalue].id,'it is the id to send')
      const starid = tostar.id || tostar[0].id;
  
      const endpoint = `${baseEndpoint}/editable/responses/${starid}/starred`;
  
      const response = await axios({
        method: 'post',
        url: endpoint,
      });
  
      const data = response.data; 
      console.log(data, "it is the new response data");
  
      setassignmentResponseData(prevData => {
        const updatedData = [...prevData];
        updatedData[tostarvalue] = [data];
  
        return updatedData; 
      });
      
      setStarred(data.starred)
  
    } catch (error) {
      console.error('Error:', error);
    }
  };

  
  
  const generatePDF = (questionsData, title) => {
    const doc = new jsPDF();

    const addWatermark = () => {
        doc.setFontSize(50);
        doc.setFont("helvetica", "bold");

        const pageWidth = doc.internal.pageSize.width;
        const pageHeight = doc.internal.pageSize.height;

        const logoPath = "/images/Black-Logo.png";
        const logoWidth = 160;
        const logoHeight = 30;

        const logoX = (pageWidth - logoWidth) / 2 + 35;
        const logoY = (pageHeight - logoHeight) / 2 + 15;

        doc.setGState(new doc.GState({ opacity: 0.1 }));
        doc.addImage(logoPath, "PNG", logoX, logoY, logoWidth, logoHeight, undefined, 'FAST', 45);

        doc.setGState(new doc.GState({ opacity: 1 }));
    };

    const centerTextWithUnderline = (text, y) => {
        const pageWidth = doc.internal.pageSize.width;
        const textWidth = doc.getStringUnitWidth(text) * doc.internal.scaleFactor;
        const x = (pageWidth - (textWidth * 2)) / 2;

        doc.text(text, x, y);
        const lineY = y + 2; 
        doc.line(x, lineY, x + textWidth * 2.3, lineY);
        doc.line(x, lineY + 1, x + textWidth * 2.3, lineY + 1);
    };

    const addQuestionsAndAnswers = (questions, startY, pageNumber) => {
        let y = startY;
        questions.forEach((item, index) => {
            doc.setFontSize(14);
            doc.setFont("helvetica", "bold");
            const questionText = (index + 1) + '. ' + item.question;
            const questionLines = doc.splitTextToSize(questionText, 150);
            const questionHeight = questionLines.length * 10;

            if (y + questionHeight > 250) {
                addPageFooter(pageNumber);
                doc.addPage();
                pageNumber++;
                addWatermark();
                y = 20;
                doc.setFontSize(14); 
            }

            questionLines.forEach(line => {
                doc.text(line, 20, y);
                y += 10;
            });

            // Add answer directly below the question
            doc.setFont("helvetica", "italic");
            doc.setFontSize(12);
            doc.setTextColor(0, 128, 0);
            const answerText = `Answer: ${item.answer}`;
            const answerLines = doc.splitTextToSize(answerText, 150);
            answerLines.forEach(line => {
                doc.text(line, 20, y);
                y += 10;
            });

            // Reset to default font for the next question
            doc.setFont("helvetica", "bold");
            doc.setTextColor(0, 0, 0);
            y += 5; // Add spacing between questions
        });

        return pageNumber;
    };

            // Centered multi-line text
            const centerMultilineText = (lines, startY) => {
                const pageWidth = doc.internal.pageSize.width;
                let y = startY;
        
                lines.forEach(line => {
                    const textWidth = doc.getStringUnitWidth(line) * doc.internal.scaleFactor;
                    const x = (pageWidth - textWidth * 2) / 2;
                    doc.text(line, x, y);
                    y += 8; // Adjust line spacing
                });
        
                return y; // Return the updated Y position
            };

    const addPageFooter = (pageNumber) => {
        const pageText = `Page ${pageNumber}`;
        doc.setFontSize(10);
        const pageWidth = doc.internal.pageSize.width;
        const textWidth = doc.getStringUnitWidth(pageText) * doc.internal.scaleFactor;
        const x = (pageWidth - textWidth) / 2;
        doc.text(pageText, x, 290);
    };

    let y = 30;
        // Document Header
        doc.setFontSize(18);
        doc.setFont("helvetica", "bold");
        centerTextWithUnderline(`Class ${title.standard} - ${title.subject}`, 10);

        // Add filenames below the title
        if (title.file_name && title.file_name.length > 0) {
            doc.setFontSize(12);
            const pageWidth = doc.internal.pageSize.width;
            const margin = 20; // Left and right margins
            let y = 20; // Starting Y position
        
            // Prepare the text
            const topicsText = `Topics:`; // Bold part
            const fileNames = title.file_name.join(', '); // Normal part
        
            // Calculate text widths
            doc.setFont("helvetica", "bold");
            const topicsWidth = doc.getTextWidth(topicsText);
            doc.setFont("helvetica", "normal");
            const fileNamesWidth = doc.getTextWidth(fileNames);
        
            // Total width of the combined text
            const totalWidth = topicsWidth + fileNamesWidth + 2; // Adding some spacing
        
            // Calculate the starting X position for center alignment
            const startX = (pageWidth - totalWidth) / 2;
        
            // Render "Topics:" in bold
            doc.setFont("helvetica", "bold");
            doc.text(topicsText, startX, y);
        
            // Render file names in normal font next to "Topics:"
            doc.setFont("helvetica", "normal");
            doc.text(fileNames, startX + topicsWidth + 2, y); // Add spacing after "Topics:"
        
            doc.setTextColor(0); // Reset the text color
        }  


    const topicText = title.subtopic ? `(${title.subtopic})` : '';
    if (topicText) {
        doc.setFont("helvetica", "italic");
        const topicLines = doc.splitTextToSize(topicText, doc.internal.pageSize.width - 20);
        topicLines.forEach((line, index) => {
            doc.text(line, 10, y + (index * 8));
        });

        y += topicLines.length * 10 + 10;
    }

    addWatermark();

    doc.setFontSize(16);
    doc.text('Questions and Answers', 20, y);
    y += 10;
    let pageNumber = 1;
    pageNumber = addQuestionsAndAnswers(questionsData, y, pageNumber);

    addPageFooter(pageNumber);

    doc.save(`class_${title.standard}-${title.subject}.pdf`);
};
    

    
    
      const contentRef = useRef(null);
    
    // const copyToClipboard = (key) => {
    //   const { response } = assignmentResponseData[key];
    //   const responseData = response.map((item, index) => {
    //       return `${index + 1}. ${item.question}\n\nAnswer: ${item.answer}`;
    //   }).join('\n\n');
    
    //   navigator.clipboard.writeText(responseData)
    //       .then(() => {
    //           alert('Response copied to clipboard!');
    //           console.log(key,"itiskey in copycode")
    //       })
    //       .catch(err => {
    //           console.error('Failed to copy: ', err);
    //       });
    // };

    const copyToClipboard = (key) => {
      const selectedQuestions = questions[key]; // Access the specific inner array based on the key
  
      if (!selectedQuestions || selectedQuestions.length === 0) {
          alert('No questions found for this key!');
          return;
      }
  
      const responseData = selectedQuestions.map((item, index) => {
          return `${index + 1}. ${item.question}\nAnswer: ${item.answer}`;
      }).join('\n\n');
  
      navigator.clipboard.writeText(responseData)
          .then(() => {
              alert('Response copied to clipboard!');
              console.log(key, "is the key in copy code");
          })
          .catch(err => {
              console.error('Failed to copy: ', err);
          });
    };
    const formPageRef = useRef();
      console.log(formPageRef)
      const handleClearResponse = (e) => {
        e.preventDefault();

        // Reset MCQ-specific states
        setQuestions([]);  // Clear the questions array
        setassignmentResponseData([]);  // Clear the response data
        setquestionsHeading(null);  // Reset the heading

        // Call the form clearing function
        if (formPageRef.current) {
            console.log("Calling clearForm...");
            formPageRef.current.clearForm(); // This will call the clearForm method in Formpage
        } else {
            console.log(formPageRef);
            console.log("formRef is not set!");
        }
    };

    const itemData = location.state;
    useEffect(() => {
        if (itemData && itemData.response) {
          console.log(itemData, "it is the data from the history");
          setQuestions([itemData.response]); // Set questions only if itemData.response exists
          setassignmentResponseData([itemData]);    // Set mcqResponseData only if itemData exists
          setStarred(itemData.starred);
          if(isstarred){
            setIshistory(true)
        }
          setquestionsHeading(itemData);
        }
      }, [itemData]);
    return(
        <div className="maincontainer">
        <div className="main-content">
            <div className="header">
                <div className="header-left">
                    <div className="icon-container">
                        <div className="icon-circle">
                            <img
                                src="/images/rectangles.svg"
                                alt=""
                                className="icon-img"
                            />
                        </div>
                        <p className="header-text">
                        {questionsHeading ? `${questionsHeading.number} Assignment Questions on ${questionsHeading.subject} for Class ${questionsHeading.standard} (${questionsHeading.complexity.charAt(0).toUpperCase() + questionsHeading.complexity.slice(1)}  Difficulty)` : 'Assignment Questions'}
                        </p>
                    </div>
                    <a href="#" onClick={handleClearResponse} className="clear-response">Clear Response</a>
                </div>
            </div>

            {/* <div className="questions-container" ref={contentRef}>
                </div> */}
                    {loading ? (
                        <div style={{width:'100%',height:"75vh",display:'flex',alignItems:'center',justifyContent:'center',paddingRight:'20%'}}>
                            <img src="/images/Animation-2.gif" alt="" style={{width:'50px',height:'80px'}} />
                        </div>
                    ) : (
                        <div style={{width:"100%",height:'75vh',overflowY:'scroll'}}> 
                        {renderQuestions()}
                        </div>
                    )}

            <div className="footer">
                <div className="footerbox">
                    {/* <div className="footer-left">
                        <b onClick={() => copyToClipboard(assignmentResponseData.length-1)} className="footer-icon">
                            <GrCopy />
                        </b>
                        <b onClick={() => generatePDF(questions[assignmentResponseData.length-1], questionsHeading)} className="footer-icon">
                            <LuDownload />
                        </b>
                        <b 
                            onClick={() => handlesave(assignmentResponseData.length - 1)} 
                            className="footer-icon"
                            >
                                {isstarred
                                    ? <IoBookmark />
                                    : <CiBookmark />
                                }
                            </b>
                    </div> */}
                    <button
                        onClick={(event) => handleVariant(event)}
                        className="regenerate-button"
                        disabled={isregenareteshow || isstarred||ishistory}
                    >
                        <img src='/images/Vector.svg' className="button-icon" />
                        Regenerate response
                    </button>
                </div>
            </div>
        </div>

        <div className="formbase">
            <Formpage 
                ref={formPageRef}
                onAnswersToggle={handleAnswersToggle}
                onFormSubmit={handleSubmit}
            />
        </div>
    </div>
    );
};
export default Assignments;