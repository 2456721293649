import React, { useEffect, useRef, useState } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { useNavigate, useLocation } from "react-router-dom";

import './Header.css';
import { FaCaretDown } from "react-icons/fa";
import { baseEndpoint } from "./API/endpoints";
import axios from "axios";

const RightSection = ({ children, leftWidth, isMobileView, toggleLeftMenu }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const containerRef = useRef(null);
  const userIconRef = useRef(null);  // Ref to the user icon
  const [selectedOption, setSelectedOption] = useState('');
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);

  // Mapping of routes to their corresponding dropdown options
  const routeToOptionMap = {
    '/mainform': location.state?.type || '',
    '/mcq': 'mcq',
    '/assignment': 'assignment',
    '/assignement': 'assignment',
    '/summarization': 'summarization'
  };

  useEffect(() => {
    // Determine selected option based on current route
    const currentRoute = location.pathname;
    const mappedOption = routeToOptionMap[currentRoute];
    
    // If a mapping exists, set the selected option
    if (mappedOption !== undefined) {
      setSelectedOption(mappedOption);
    } else {
      // Default to empty if no mapping found
      setSelectedOption('');
    }
  }, [location.pathname, location.state]);

  // Handle change in the select dropdown
  const handleChange = (event) => {
    const selectedValue = event.target.value;
    switch (selectedValue) {
      case 'mcq':
        navigate('/mainform', { state: { type: selectedValue } });
        break;
      case 'assignment':
        navigate('/mainform', { state: { type: selectedValue } });
        break;
      case 'summarization': 
        navigate('/summarization');
        break;
      default:
        navigate('/');
        break;
    }
  };

  // Handle user sign out
  const handleSignOut = () => {
    localStorage.clear();
    navigate('/signin');
  };

  // Toggle user dropdown visibility
  const toggleUserDropdown = () => {
    setIsUserDropdownOpen(!isUserDropdownOpen);
  };

  // Close the dropdown if clicked outside
  const handleClickOutside = (event) => {
    if (userIconRef.current && !userIconRef.current.contains(event.target)) {
      setIsUserDropdownOpen(false);
    }
  };

  // Add event listener to handle click outside the user icon
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


const LogoutMethod = async (e) => {
    e.preventDefault();
    var endpoint = '';

    var method = '';
    var tokenType ='';
    var AccessToken = '';

    if(localStorage.getItem('access_token')){
      console.log("it is for userlogout processs")
      // aws endpoint 
      endpoint = `${baseEndpoint}/userauth/logout` 
      method = 'POST';
      tokenType = localStorage.getItem('token_type');
      AccessToken = localStorage.getItem('access_token');
    }else{
      console.log("it is for Googlelogout processs")
      // aws endpoint
      endpoint = `${baseEndpoint}/googleauth/google/logout`
      method = 'GET';
      tokenType = 'Token';
      AccessToken = localStorage.getItem('token');
      console.log(AccessToken)
      

    }
  
    console.log(tokenType,"qqqq",AccessToken,"it is seeted value")
    axios({
      method: method,
      url: endpoint,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        "Authorization":`${tokenType} ${AccessToken}`,
        
      },  
    }).then(response => {
      console.log(response,"it is form response");

      if (response.status === 200 || response.data.msg ==="Token expired or revoked") {
        localStorage.clear()
        navigate('/signin');
      } else {
        console.error("Login failed with status:", response.status);
      }
      // Handle successful response
    }).catch(error => {
      if (error.response.data.msg ==="Token expired or revoked") {
        localStorage.clear()
        navigate('/signin');
      }
      console.error("There was an error!", error.response);
    });
  };
  const [userInitials, setUserInitials] = useState('');
  const [userpresent,setUserpresent]=useState(false)

  useEffect(() => {
    // Retrieve user data from localStorage
    const userData = JSON.parse(localStorage.getItem('userdetail'))|| JSON.parse(localStorage.getItem('userdata'));
    if (userData) {
      const full_name = userData.full_name.charAt(0).toUpperCase();
      // const lastLetter = userData.lastName.charAt(0).toUpperCase();
      setUserInitials(full_name); // Combine initials
      setUserpresent(true)
    }
  }, []);
  return (
    <div 
      ref={containerRef} 
      className="right-section" 
      style={{ 
        flex: 1, 
        marginLeft: leftWidth !== '5%' ? leftWidth : '0',
        transition: 'margin-left 0.4s ease'
      }}
    >
      {/* Header Section */}
      <div className="headheader">
        <div className="select-container">
          <select 
            className="Headercustom-select" 
            onChange={handleChange} 
            value={selectedOption}
          >
            <option value="">Select</option>
            <option value="mcq">MCQ</option>
            <option value="assignment">Assignment</option>
            {/* <option value="#">Summarization</option> */}
          </select>
          <div className="select-arrow"><FaCaretDown /></div>
        </div>

        <div className="user-icon-container position-relative " ref={userIconRef}>
        <div
          className="user-icon d-flex align-items-center justify-content-center"
          onClick={toggleUserDropdown}
          style={{ cursor: 'pointer' }}
        >
          {userInitials ? (
            <span style={{ color: 'white', fontSize: '16px', fontWeight: 'bold' }}>
              {userInitials}
            </span>
          ) : (
            <AiOutlineUser style={{ color: 'white', fontSize: '30px' }} />
          )}
        </div>   
          {isUserDropdownOpen && (
            <div 
              className="user-dropdown position-absolute bg-white shadow rounded" 
              style={{ 
                top: '100%', 
                right: 0, 
                zIndex: 2000, 
                minWidth: '150px' 
              }}
            >
              {userpresent ?(

              <button 
                className="dropdown-item" 
                onClick={LogoutMethod}
              >
                Sign Out
              </button>
              ):(
                <button 
                className="dropdown-item" 
                onClick={()=>{navigate('/signin');}}
              >
                Sign in
              </button>
              )

              }
            </div>
          )}
        </div>
      </div>

      {/* Child Content Section */}
      <div className="content-section child-component">
        {children}
      </div>
    </div>
  );
};

export default RightSection;
