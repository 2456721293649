import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import BaseLayout from './components/Base';
import Main from './components/pages/Main';
import MCQ from './components/pages/Mcq';
import Signup from './components/pages/signup/Signup';
import Signin from './components/pages/Signin';
import Forgotpasswrod from './components/pages/Forgotpassword/Forgotpassword';
import Setpassword from './components/pages/Forgotpassword/Setpassword';
import Restmessage from './components/pages/Forgotpassword/Restmessage';
import Mainform from './components/pages/Mainform/Mainform';
import Assignments from './components/pages/Assignement/Assignement';
import Formpage from './components/pages/Mainform/Rightsideform/Form';
import Googledata from './components/pages/googledata';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path='/' element={<BaseLayout/>}/> */}

        <Route path="/" element={<BaseLayout><Main /></BaseLayout>} />
        <Route path='/mcq' element={<BaseLayout><MCQ/></BaseLayout>}/>
        <Route path='/mainform' element={<BaseLayout><Mainform/></BaseLayout>}/>
        <Route path='/signin' element={<Signin/>}/>
        <Route path='/signup' element={<Signup/>}/>
        <Route path='/forgotpassword' element={<Forgotpasswrod/>}/>
        <Route path='/setpassword' element={<Setpassword/>}/>
        <Route path='/passwordmessage' element={<Restmessage/>}/>
        <Route path='/form' element={<Formpage/>}/>
        <Route path='/assignment' element={<BaseLayout><Assignments/></BaseLayout>}/>
        <Route path='/googleauth/google' element={<Googledata/>}/>

      </Routes>
    </BrowserRouter>
  );
}

export default App;
