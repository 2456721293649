import React, { useEffect, useState } from "react";
import './Signin.css'
import { PiSignOutLight } from "react-icons/pi";
import { FcGoogle } from "react-icons/fc";
import { FaEnvelope, FaEye, FaEyeSlash } from "react-icons/fa";
import { GoLock, GoMail } from "react-icons/go";
import { baseEndpoint } from "../API/endpoints";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import queryString from 'query-string';

const Signin =()=>{ 
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });
  const [Google, setGoogleurl] = useState('');

  const handleGoogleLogin = () => {
    window.location.href = `${Google.url}`;
  };

  const userHandler = async () => {
    var endpoint = `${baseEndpoint}/googleauth/google/login`;
    var method = 'GET';

    axios({
      method: method,
      url: endpoint,
    }).then((response) => {
      const Googledata = response.data;
      setGoogleurl(Googledata);
    });
  };

  useEffect(() => {
    userHandler();
  }, []);
  // const handleGoogleLogin = async () => {
  //   if (isLoadingGoogle) return;
    
  //   setIsLoadingGoogle(true);
  //   try {
  //     const response = await axios.get(`${baseEndpoint}/googleauth/google/login`);
  //     const googleUrl = response.data.url;
  //     if (googleUrl) {
  //       window.location.href = googleUrl;
  //     }
  //   } catch (error) {
  //     console.error('Failed to get Google login URL:', error);
  //     alert('Failed to initialize Google login. Please try again.');
  //   } finally {
  //     setIsLoadingGoogle(false);
  //   }
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading) return;
    
    setIsLoading(true);
    const endpoint = `${baseEndpoint}/userauth/token`;
    
    // Format data to match OAuth2PasswordRequestForm
    const sendData = new URLSearchParams();
    sendData.append('username', formData.username);
    sendData.append('password', formData.password);

    try {
      const response = await axios({
        method: 'post',
        url: endpoint,
        data: sendData.toString(),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      // Store user data from response
      if (response.data.access_token) {
        localStorage.setItem('access_token', response.data.access_token);
        localStorage.setItem('token_type', response.data.token_type);
        localStorage.setItem('userdetail', JSON.stringify(response.data.user));
        navigate('/');
      }
    } catch (error) {
      if (error.response) {
        alert(
          `Error: ${error.response.data.detail || 'An error occurred. Please try again.'}`
        );
      } else if (error.request) {
        alert('No response received from server. Please try again.');
      } else {
        alert('An error occurred while setting up the request. Please try again.');
      }
      console.error('There was an error!', error);
    } finally {
      setIsLoading(false);
    }
  };
    return(
        <div 
        className="base d-flex flex-column align-items-center justify-content-center "
        >
            <div 
            className="box1 d-flex flex-column align-items-center justify-content-start"
            >
                <div className="imgbox d-flex align-items-center justify-content-center  " 
                >
                    <div
                    className="d-flex align-items-center justify-content-center me-2"
                    >
                    <img
                        src="/images/Logo.svg"
                        alt=""
                        
                    />
                    </div>
                    <img 
                        src="/images/Group 10.svg" alt=""
                     />
                </div>
                <p className="heading">
                Sign In To Your Account.
                </p>
                <form onSubmit={handleSubmit} action="" className="singinform">
                    <div className="singinformbox">
                        <label className="emaillabel" htmlFor="email"> Email Address</label>
                        <div className="input-container">
                            
                            <GoMail className="icon"  />
                            <input className="inputbox" name="username" onChange={handleChange} type="email" id="username" placeholder="example@gmail.com" />
                        </div>
                    </div>
                    <div className="singinformbox">
                        <label className="emaillabel" htmlFor="email"> Password</label>
                        <div className="input-container">
                            <GoLock className="icon"/>
                            <input className="inputbox" id="password" name="password"  type={showPassword ? 'text' : 'password'} onChange={handleChange} placeholder="*****************" />
                            <span
                                onClick={() => setShowPassword(!showPassword)}
                                
                                className="password-toggle-icon"
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    right: '20px',
                                    transform: 'translateY(-50%)',
                                    cursor: 'pointer',
                                }}
                            >
                                {showPassword ? <FaEye /> : <FaEyeSlash />}
                            </span>
                        </div>
                    </div>
                    <div className="formbox-2">
                        <div className="remember-wrapper">
                            <input type="checkbox" id="remember" />
                            <span>Remember me</span>
                        </div>
                        <a href="/forgotpassword" className="forgot-link">Forgot Password</a>
                    </div>
                    <button 
                      type="submit" 
                      className="submit"
                      disabled={isLoading}
                    >
                        {isLoading ? 'Signing in...' : 'Sign In'}
                        {!isLoading && <PiSignOutLight style={{fontSize:'26px', marginLeft: '8px'}} />}
                    </button>
                </form>
                <div className="googlebox d-flex flex-column align-items-center justify-content-center">
                    <p className="singup" >Don’t have an account? <a href="/signup">Sign up</a></p>
                    <div class="border-between">
                        OR
                    </div>
                    <button 
                      onClick={handleGoogleLogin} 
                      className="googlebutton d-flex align-items-center justify-content-center"
                      disabled={isLoadingGoogle}
                    >
                      <FcGoogle style={{fontSize:'26px'}}/>
                      <p>
                        {isLoadingGoogle ? 'Loading...' : 'Or sign in with Google'}
                      </p>
                    </button>
                </div>
            </div>
            <div className="copybox d-flex align-items-center justify-content-center">
                <p className="copytext">Copyright 2024 Transpoze</p>
            </div>
        </div>
    );
};

export default Signin;