import React from "react";
import './Restmessage.css'
import { PiSignOutLight } from "react-icons/pi";

const Restmessage = ()=>{
    return(
        <div 
        className="base d-flex flex-column align-items-center justify-content-center "
        >
            <div 
            className="box1 d-flex flex-column align-items-center justify-content-start mesbox"
            >
                <p className="mesheading">
                Reset Password
                </p>
                <div
                className="d-flex align-items-center justify-content-center me-2"
                style={{
                    width: "180px",
                    height: "180px",
                    borderRadius: "50%",

                }}
                >
                <img
                    src="/images/Successful.gif"
                    alt=""
                    style={{
                    width: "100%",
                    height: "100%",
                    }}
                />
                </div>
                <p className="message">Your Password Successfully Updated </p>
                <button className="submit">
                    Back to login
                    <PiSignOutLight className="ms-2" style={{fontSize:'26px'}} />    
                </button>
            </div>
        </div>
    );
};

export default Restmessage;