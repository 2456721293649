import React, { useState } from "react";
import './Forgotpassword.css'
import { GoChevronLeft, GoMail } from "react-icons/go";
import { FiLock } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import axios from 'axios';
import { baseEndpoint } from "../../API/endpoints";

const Forgotpasswrod = () => {
    const navigate = useNavigate();
    const [message, setMessage] = useState({ text: '', type: '' });
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setMessage({ text: '', type: '' });
        
        try {
            const response = await axios.post(`${baseEndpoint}/userauth/forgot-password`, {
                email: formData.email
            });

            if (response.status === 200) {
                setMessage({ 
                    text: 'Password reset link has been sent to your email!', 
                    type: 'success' 
                });
                setTimeout(() => {
                    navigate('/signin');
                }, 3000);
            } else {
                setMessage({ 
                    text: response.data.error || response.data.detail || response.data.message || 'Failed to send reset link. Please try again.', 
                    type: 'error' 
                });
            }
        } catch (error) {
            setMessage({ 
                text: error.response?.data?.detail || 
                      error.response?.data?.message || 
                      error.response?.data?.error || 
                      'Failed to send reset link. Please try again.', 
                type: 'error' 
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="main d-flex align-items-center justify-content-center">
            <div className="fgpbox d-flex flex-column align-items-center justify-content-start">
                <div className="imgbox d-flex align-items-center justify-content-center">
                    <div className="d-flex align-items-center justify-content-center me-2">
                        <img
                            src="/images/Logo.svg"
                            alt="Logo"
                            style={{
                                width: '80%',
                                height: '80%',
                            }}
                        />
                    </div>
                    <img 
                        src="/images/Group 10.svg" 
                        alt="Group Icon"
                        style={{
                            width: '50%',
                            height: 'auto',
                        }}
                    />
                </div>
                <div className="fgpbox2">
                    <p className="fgpheading">Reset Your Password</p>
                    <p className="fgptext">
                        Forgot your password? No worries, we'll send a password reset link to your email.
                    </p>
                </div>
                <form onSubmit={handleSubmit} className="fgpform">
                    <div className="fgpformbox">
                        <label className="emaillabel" htmlFor="email">Email Address</label>
                        <div className="input-container">
                            <GoMail className="icon" />
                            <input
                                className="inputbox"
                                name="email"
                                onChange={handleChange}
                                type="email"
                                id="email"
                                value={formData.email}
                                placeholder="example@gmail.com"
                                required
                            />
                        </div>
                    </div>
                    {message.text && (
                        <div className={`message ${message.type}`}>
                            {message.text}
                        </div>
                    )}
                    <button 
                        type="submit" 
                        className="fgpsubmit d-flex align-items-center justify-content-center gap-2"
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <>
                                <AiOutlineLoading3Quarters className="loading-icon spin" />
                                Sending...
                            </>
                        ) : (
                            <>
                                Reset Password
                                <FiLock style={{fontSize: '26px'}} />
                            </>
                        )}
                    </button>
                </form>
                <div className="back-to-login">
                    <a href="/signin" className="d-flex align-items-center text-decoration-none">
                        <GoChevronLeft style={{fontSize:'22px',color:'#2c67f2'}} />
                        Back to login screen
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Forgotpasswrod;